.footer {
  background-color: #141414;
  padding: 38px 0 0;
  .btm {
    margin-bottom: 44px;
  }
  .lined {
    border-top: 1px solid #969696;
    padding: 14px 0 28px;
    p {
      margin-bottom: 0;
      color: #969696;
      font-size: 14px;
      font-weight: 400;
    }
    a {
      margin-bottom: 0;
      color: #969696;
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
      position: relative;
    }
  }
  .txt {
    max-width: 354px;
    line-height: 1.2;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    display: block;
    text-decoration: none;
  }
  .logo {
    margin-bottom: 25px;
    display: block;
    img {
      width: 60px;
    }
  }
  .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    .l_block {
      min-width: 142px;
      width: 142px;
      div,
      a {
        display: block;
        width: 142px;
        margin-bottom: 12px;
        font-size: 16px;
        color: #fff;
        font-weight: 400;
        text-decoration: none;
        line-height: 1;
        &:last-child {
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

.lgs {
  margin: 32px auto 0;
  max-width: 90%;
}

.to_top {
  color: #d2a367;
  background-color: #1f201f;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  text-align: center;
  font-size: 26px;
  padding-top: 6px;
  transition: all 0.3s ease;
  z-index: 9999999;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 768px) {
  .to_top {
    bottom: 20px !important;
    right: 20px !important;
  }
}

@media screen and (max-width: 960px) {
  .f_block {
    margin-top: 32px !important;
  }
  .lgs {
    margin-top: -22px;
  }
}

@media screen and (max-width: 960px) {
  .btm {
    padding-top: 10px;
    margin-bottom: 35px !important;
  }
  .footer {
    padding: 20px 0 0 !important;
  }
  .logo {
    margin-bottom: 20px !important;
    img {
      width: 50px !important;
    }
  }
  .txt {
    font-size: 12px !important;
    max-width: 203px !important;
  }
  .links {
    .l_block {
      width: 125px !important;
      a,
      div {
        width: 125px !important;
        font-size: 12px !important;
      }
    }
  }
  .lined {
    p,
    a {
      font-size: 12px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .btm {
    margin-bottom: 30px !important;
  }
  .footer {
    padding: 20px 0 0 !important;
  }
  .logo {
    margin-bottom: 30px !important;
    img {
      width: 35px !important;
    }
  }
  .txt {
    font-size: 16px !important;
    max-width: 100% !important;
    margin-bottom: 30px !important;
  }
  .links {
    &.lmob {
      margin-top: 30px !important;
    }
    .l_block {
      width: 171px !important;
      a,
      div {
        width: 171px !important;
        font-size: 16px !important;
      }
    }
  }
  .lined {
    p,
    a {
      font-size: 14px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .logo {
    width: 100% !important;
    text-align: center;
    img {
      width: 35px !important;
    }
  }
}

@media screen and (min-width: 1025px) {
  .logo {
    img {
      transition: all 0.4s ease;
      &:hover {
        transform: scale(0.9);
      }
    }
  }
  .txt {
    position: relative;
  }
}

@media screen and (min-width: 1600px) {
  .logo {
    margin-bottom: 25px !important;
    img {
      width: 80px !important;
    }
  }
}

@media screen and (min-width: 1920px) {
  .footer {
    padding: 77px 0 0;
  }
  .logo {
    margin-bottom: 35px !important;
    img {
      width: 100px !important;
    }
  }
  .txt {
    font-size: 22px !important;
    max-width: 478px !important;
  }
  .links {
    .l_block {
      width: 213px !important;
      a,
      div {
        width: 213px !important;
        font-size: 22px !important;
      }
    }
  }
  .lined {
    p,
    a {
      font-size: 20px !important;
    }
  }
}