.header {
  position: fixed;
  width: 100%;
  z-index: 999999;
  transition: all 0.5s ease;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  top: 0;
  &.fixed {
    //box-shadow: 0 6px 10px rgb(0 0 0 / 5%);
    top: -100px;
    .menu {
      .menu_item {
        //color: rgba(0, 0, 0, 0.8);
        &:hover {
          //color: #000;
        }
      }
    }
  }
  &.noblur {
    backdrop-filter: blur(0px) !important;
  }
  &.nfilled {
    background-color: transparent !important;
    .menu_item {
      color: #fff !important;
    }
    .langs {
      div {
        color: #E0E0E0 !important;
        &.active {
          color: #fff !important;
        }
      }
    }
  }
  &.tgl {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  .logo {
    display: block;
    width: 100%;
    text-align: center;
    img {
      width: 80px;
      transition: all 0.4s ease;
      &:hover {
        transform: scale(0.9);
      }
    }
  }
  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    height: 100%;
    padding: 20px 0;
    .menu_item {
      min-width: fit-content;
      margin-right: 40px;
      cursor: pointer;
      transition: color 0.3s ease;
      user-select: none;
      position: relative;
      text-decoration: none;
      font-size: 20px;
      font-weight: 400;
      line-height: 1;
      color: #000000;
      &:hover {
        color: #000000;
      }
      &.active {
        color: rgba(255, 255, 255, 1);
      }
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
  .ended {
    display: flex;
    flex-direction: row;
    align-items: center;
    .phones {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #fff;
      margin-right: 28px;
      text-shadow: 0 1px 6px rgba(0, 0, 0, 0.9);
    }
    .backcall {
      button {
        background: #FFFFFF;
        border-radius: 6px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #183F6C;
        padding: 12px 18px;
        outline: none;
        border: none;
        cursor: pointer;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      display: block;
      font-size: 12px;
      margin-right: 10px;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.7);
      transition: all 0.3s ease;
      &:hover {
        color: #fff;
      }
      &:last-child {
        margin-right: 0px;
      }
      svg {}
      img {
        width: 36px;
      }
    }
  }
  .contacts {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 32px;
    a {
      display: block;
      min-width: fit-content;
      text-decoration: none;
      &:nth-child(1) {
        margin-right: 10px;
        color: rgba(255, 255, 255, 0.7);
        transition: all 0.3s ease;
        &:hover {
          color: #fff;
        }
        span {
          color: #fff;
          margin-right: 8px;
        }
      }
      img {}
    }
  }
}

.flxd {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.ending {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  .langs {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    div {
      color: #9E9E9E;
      font-size: 20px;
      margin-right: 10px;
      cursor: pointer;
      line-height: 1;
      user-select: none;
      transition: all 0.3s ease;
      &:hover {
        color: #000;
      }
      &:last-child {
        margin-right: 0px;
      }
      &.active {
        color: #000;
      }
    }
  }
}

.centere {
  align-items: center;
  display: flex;
}

.menu_open {
  color: #000;
  font-size: 28px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: fixed;
  z-index: 999999999999999;
  top: 6px;
  left: 14px;
  transition: all 0.4s ease;
  &.tgl {
    top: -100px;
  }
  svg {
    rect {
      transition: all 0.3s ease;
      transform: translateY(0px) translateX(0px) rotate(0deg);
    }
  }
  &.openned {
    svg {
      rect {
        &:nth-child(1) {
          transform: translateY(15px) translateX(-2px) rotate(-45deg);
        }
        &:nth-child(2) {
          transform: translateY(-4px) translateX(12px) rotate(45deg);
        }
      }
    }
  }
}

.mob_menu {
  display: block;
  opacity: 1;
  width: 100%;
  height: 100lvh;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(5px);
  z-index: 99999999999;
  transition: all 0.3s ease;
  &.winter {
    background-color: rgba(99, 132, 156, 0.85) !important;
  }
  &.fixedd {
    .mm_w_logo {
      img {
        width: 80px !important;
      }
    }
    .mm_wrap {
      padding: 22px 15px !important;
    }
    .mm_w_close {
      top: 22px !important;
      right: 23px !important;
    }
  }
  .mm_wrap {
    padding: 10px 15px 26px;
    .mm_w_close {
      position: absolute;
      left: 22px;
      top: 16px;
      font-size: 28px;
      color: #fff;
      z-index: 9999999999;
    }
    .mm_w_logo {
      margin-bottom: 74px;
      width: 100%;
      text-align: center;
      img {
        width: 60px;
        position: relative;
        // top: -6px;
      }
    }
    .mm_w_contacts {
      position: absolute;
      bottom: 140px;
      display: flex;
      flex-direction: column;
      a {
        display: block;
        color: #000;
        text-decoration: none;
        font-size: 18px;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .mm_w_items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-height: 87lvh;
      overflow-y: scroll;
      a {
        display: block;
        color: rgb(0, 0, 0);
        text-decoration: none;
        font-size: 18px;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 23px;
        cursor: pointer;
        &:hover {
          color: #000;
        }
        &.active {
          color: #000 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 10px 0;
    .logo {
      img {
        width: 60px !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .menu {
    .menu_item {
      padding: 10px 0 !important;
      font-size: 12px !important;
    }
  }
  .langs {
    div {
      font-size: 12px !important;
    }
  }
  .logo {
    img {
      width: 35px !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .menu {
    padding: 10px 0 !important;
    .menu_item {
      font-size: 16px !important;
    }
  }
  .langs {
    div {
      font-size: 16px !important;
    }
  }
  .logo {
    img {
      width: 50px !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .menu_open {
    display: none !important;
  }
}

@media screen and (min-width: 960px) {
  .nfilled {
    .menu_item {
      span {
        &::before {
          background-color: rgb(255, 255, 255);
        }
      }
    }
    .langs {
      div {
        &::before {
          background-color: rgb(255, 255, 255);
        }
      }
    }
  }
  .menu_item {
    span {
      &::before {
        content: " ";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0px;
        height: 1px;
        background-color: rgb(0, 0, 0);
        transition: all 0.3s ease;
      }
    }
    &:hover {
      span {
        &::before {
          width: 100%;
        }
      }
    }
  }
  .langs {
    div {
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0px;
        height: 1px;
        background-color: rgb(0, 0, 0);
        transition: all 0.3s ease;
      }
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .menu {
    padding: 20px 0 !important;
    .menu_item {
      font-size: 20px !important;
    }
  }
  .langs {
    div {
      font-size: 20px !important;
    }
  }
  .logo {
    img {
      width: 80px !important;
    }
  }
}

@media screen and (min-width: 1920px) {
  .menu {
    padding: 32px 0 !important;
    .menu_item {
      font-size: 22px !important;
      span {
        &::before {
          bottom: 0;
        }
      }
    }
  }
  .fixed {
    top: -126px !important;
  }
  .langs {
    div {
      font-size: 22px !important;
    }
  }
  .logo {
    img {
      width: 100px !important;
    }
  }
}